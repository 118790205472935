class ZIDXAccountMlsGridServers implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountMlsGridServersContainer";
    }
    getMatchURL(){
        return "/account/data-feeds/mls-grid-server-logins";
    }
    render(){
        // console.log("mls grid server logins");
    }
}